import { dasherize } from '@ember/string';

export function initialize(applicationInstance) {
  let store = applicationInstance.lookup('service:store');
  let shoebox = applicationInstance.lookup('service:fastboot').get('shoebox');

  // Workaround for dataAdapter.getModelTypes() no longer working
  let dataAdapter = applicationInstance.lookup('data-adapter:main');
  let debugAdapter = dataAdapter.get('containerDebugAdapter');
  let modelNames = debugAdapter.catalogEntriesByType('model');

  shoebox.put('ember-data-store', {
    get records() {
      return modelNames
        .map(name => store.peekAll(name).slice())
        .reduce((a, b) => a.concat(b), [])
        .filter(record => record.get('isLoaded') && !record.get('isNew'))
        .map(record => {
          let serializedRecord = record.serialize({ includeId: true });

          record.eachRelationship((name, meta) => {
            let link = record[meta.kind](name).link();

            if (link) {
              let dashName = dasherize(name);

              serializedRecord.data.relationships = serializedRecord.data.relationships || {};
              serializedRecord.data.relationships[dashName] =
                serializedRecord.data.relationships[dashName] || {};
              serializedRecord.data.relationships[dashName].links = {
                related: link,
              };
            }
          });

          return serializedRecord;
        })
        .filter(x => x.data)
        .reduce(
          (a, b) => {
            a.data.push(b.data);
            return a;
          },
          { data: [] }
        );
    },
  });
}

export default {
  name: 'ember-data-fastboot',
  initialize,
};
