/* global FastBoot */
// NOTE: Emit errors as warning to report to Bugsnag
// https://github.com/ember-fastboot/ember-cli-fastboot/blob/2a6ed5d76b00da2106a9ba10f048aa2bef989a80/packages/ember-cli-fastboot/fastboot/initializers/error-handler.js
import Ember from 'ember'; // eslint-disable-line no-restricted-imports

export default {
  name: 'error-handler',
  initialize() {
    Ember.onerror = function (err) {
      FastBoot.require('process').emitWarning(`${err.message}\n\n${err.stack}`);
    };
  },
};
